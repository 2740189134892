<template>
	<div class="main_contacts">
		<div class="content_contacts">
			<h1 class="mb3">FAQ</h1>
			<h3 class="mb3">
				Feel free to contact us any time. we will get back to you as
				soon as we can!
			</h3>
			
			<div class="faq_content">
				<div class="faq_left">
					<ul>
						<li v-for="(item_faq, index) in pool_faq" :key="index">
							<h4 @click="show_question(item_faq.id)"
								:class="{ faq_active: choice_filed === item_faq.id }">
								<i class="fas fa-plus" v-if="choice_filed != item_faq.id"></i>
								<i class="fas fa-minus" v-if="choice_filed === item_faq.id"></i>{{ item_faq.question }}
							</h4>
							<transition name="drop">
								<p v-if="choice_filed === item_faq.id">
									{{ item_faq.answer }}
								</p>
							</transition>
						</li>
					</ul>
				</div>
				<div class="faq_right">
					<h4 class="mb2">
						Didn’t find the answer? <br> You can ask your question here
					</h4>
					<textarea v-model="questionText" class="mb2" name="" id="" cols="30" rows="10"></textarea>
					<button @click="sendQuestion" class="mb2">SEND</button>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import { validationMixin } from 'vuelidate';
// import { required, email, minLength } from 'vuelidate/lib/validators';
import axios from '../axios';


export default {
	name: "faq-main",
	data() {
		return {
			faq1: false,
			faq2: false,
			faq3: false,
			faq4: false,
			pool_faq: null,
			open_some_filed: false,
			choice_filed: null,
			questionText: '',
			questionError: null,
		};
	},
	methods: {
		async getFAQ() {
			var self = this
			try {
				await axios.get('v1/faq/', {
					headers: {
						'Content-Type': 'application/json'
					}
				})
					.then(function (response) {
						self.pool_faq = response.data
					})
					.catch(function (error) {
						console.error(error);
					});

			} catch (e) {
				console.error("Error : getFAQ()")
			}
		},
		async sendQuestion() {
			var self = this
			try {
				await axios.post('v1/faq/create_question/', {
					headers: {
						'Content-Type': 'application/json',
					},
					data: {
						'question': self.questionText,
					}
				})
					.then(function (response) {
						if (response['data'] === 'Question send successfully!') {

							self.$vs.loading.close();
							self.$vs.notify({
								title: "Success",
								text: "Question send successfully!",
								iconPack: "feather",
								icon: "icon-alert-circle",
								color: "success",
							});
							self.questionText = ""
						} else {
							self.$vs.loading.close();
							self.$vs.notify({
								title: "Error",
								text: "Question is empty!",
								iconPack: "feather",
								icon: "icon-alert-circle",
								color: "danger",
							});
						}
					})
					.catch(function (error) {
						// console.error(error);
						self.$vs.loading.close();
						self.$vs.notify({
							title: "Error",
							text: error,
							iconPack: "feather",
							icon: "icon-alert-circle",
							color: "danger",
						});
					});

			} catch (e) {
				console.error("Error : getFAQ()")
			}
		},
		show_question(id) {
			this.open_some_filed = true
			this.choice_filed = id
		}
	},
	mounted() {
		this.getFAQ();
	}

};
</script>
<style scoped>
.faq_dashboard {
	color: #fff;
}

.question-error {
	color: red;
}

.faq_dashboard h3 {
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
}

.faq_content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.faq_left {
	width: 70%;
}

.faq_left ul {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	justify-content: space-between;
}

.faq_left ul li {
	width: 48%;
	margin-bottom: 50px;
}

.faq_left ul li h4 {
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 20px;
	cursor: pointer;
}

.faq_left ul li h4:hover {
	color: #5be7c4;
}

.faq_active {
	color: #5be7c4;
}

.faq_left ul li h4 i {
	margin-right: 10px;
}

.faq_left ul li p {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	max-height: 500px;
}

/* Animation */
.drop-enter,
.drop-leave-to {
	transform: translateY(-20%);
	opacity: 0;
}

.drop-enter-active {
	transition: all 0.3s ease;
}

.drop-leave-active {
	transition: all 0.3s ease;
}

.faq_right {
	width: 25%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.faq_right h4 {
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
}

.faq_right textarea {
	background-color: transparent;
	height: 70px;
	width: 92%;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 23px;
	padding: 3%;
	color: #fff !important;
	border: solid 1px #fff !important;
}

.faq_right textarea::placeholder {
	color: #fff;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
}

.faq_right textarea:focus {
	outline: none;
}

.faq_right button {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	color: #2a3587;
	background: #fff;
	border: none;
	width: 100%;
	padding: 10px 0;
	cursor: pointer;
	border: solid 1px #fff;
}

.faq_right button:hover {
	font-size: 20px;
	color: #fff;
	background: #2a3587;
}

@media (min-width: 1200px) and (max-width: 1440px) {}

@media (min-width: 992px) and (max-width: 1199px) {
	.faq_dashboard h3 {
		font-size: 20px;
		line-height: 24px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.faq_dashboard h3 {
		font-size: 18px;
		line-height: 24px;
	}
}

@media (min-width: 480px) and (max-width: 767px) {
	.faq_dashboard h3 {
		font-size: 18px;
		line-height: 24px;
		font-weight: 700;
	}

	.faq_content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		margin-bottom: 30px;
	}

	.faq_left {
		width: 90%;
	}

	.faq_left ul li h4 {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 20px;
		text-align: center;
	}

	.faq_left ul li p {
		font-size: 14px;
		line-height: 18px;
		text-align: center;
	}

	.faq_right {
		width: 90%;
	}

	.faq_right h4 {
		font-size: 16px;
		line-height: 20px;
	}

	.faq_right button {
		width: 30%;
		margin: 0 auto;
	}
}

@media (max-width: 479px) {
	.faq_dashboard h3 {
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
	}

	.faq_content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		margin-bottom: 30px;
	}

	.faq_left {
		width: 90%;
	}

	.faq_left ul li h4 {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 20px;
		text-align: center;
	}

	.faq_left ul li p {
		font-size: 14px;
		line-height: 18px;
		text-align: center;
	}

	.faq_right {
		width: 90%;
	}

	.faq_right h4 {
		font-size: 16px;
		line-height: 20px;
	}

	.faq_right button {
		width: 30%;
		margin: 0 auto;
	}
}

.main_contacts {
	width: 85%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-image: url("../assets/BG_RIGHT.png");
	background-repeat: repeat-y;
	background-position: right;
	background-size: 15%;
	color: #fff;
}

.content_contacts {
	width: 84%;
	margin-bottom: 150px;
}

.content_contacts h1 {
	font-style: normal;
	font-weight: 900;
	font-size: 72px;
	line-height: 83px;
	text-align: left;
}

.content_contacts h3 {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
}

.block_content_contacts {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.block_content_contacts_l,
.block_content_contacts_r {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 50%;
	padding: 5%;
}

.block_content_contacts_l label {
	margin-bottom: 20px;
	color: red;
}

.block_content_contacts_l input {
	margin-bottom: 10px;
	height: 25px;
	width: 100%;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	padding: 0;
	color: #fff !important;
	border-bottom: solid 1px #fff !important;
	background-color: transparent;
	box-shadow: none;
	border-color: transparent;
}

.block_content_contacts_l input::placeholder {
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	/* identical to box height */

	color: #cccccc;
}

.block_content_contacts_l input:focus {
	outline: none;
}

.block_content_contacts_l button:hover {
	background: #2a3587;
	border: 1px solid #ffffff;
	color: #fff;
}

.block_content_contacts_l button {
	cursor: pointer;
	background: #ffffff;
	border: 1px solid #ffffff;
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	color: #2a3587;
	padding: 0 50px;
	height: 50px;
	width: 100%;
}

.block_content_contacts_r a {
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	text-decoration: none;
	color: #fff;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
}

.block_content_contacts_r a i {
	width: 10%;
	text-align: center;
}

.block_content_contacts_r a span {
	width: 90%;
}

@media (min-width: 1200px) and (max-width: 1440px) {
	.content_contacts h1 {
		font-size: 60px;
		line-height: 60px;
	}

	.content_contacts {
		width: 80%;
	}

	.content_contacts h3 {
		font-style: normal;
		font-weight: 600;
		font-size: 22px;
		line-height: 24px;
		width: 80%;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.content_contacts h1 {
		font-size: 48px;
		line-height: 48px;
	}

	.content_contacts {
		width: 80%;
	}

	.content_contacts h3 {
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 22px;
		width: 80%;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.main_contacts {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background-image: none;
		background-repeat: no-repeat;
		background-position: unset;
		background-size: 0;
		padding: 0 2%;
	}

	.content_contacts {
		width: 100%;
		margin-bottom: 100px;
	}

	.content_contacts h1 {
		font-size: 42px;
		line-height: 42px;
	}

	.content_contacts h3 {
		font-size: 18px;
		line-height: 20px;
		width: 70%;
	}

	.block_content_contacts_l,
	.block_content_contacts_r {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 40%;
		padding: 4%;
	}

	.block_content_contacts_l input {
		font-size: 18px;
		line-height: 24px;
	}

	.block_content_contacts_l input::placeholder {
		font-size: 18px;
		line-height: 24px;
		/* identical to box height */

		color: #cccccc;
	}

	.block_content_contacts_r a {
		font-size: 20px;
		line-height: 20px;
	}
}

@media (min-width: 480px) and (max-width: 767px) {
	.main_contacts {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background-image: none;
		background-repeat: no-repeat;
		background-position: unset;
		background-size: 0;
		padding: 0 5%;
	}

	.content_contacts {
		width: 100%;
		margin-bottom: 100px;
	}

	.content_contacts h1 {
		font-size: 36px;
		line-height: 36px;
	}

	.content_contacts h3 {
		font-size: 16px;
		line-height: 18px;
		width: 80%;
	}

	.block_content_contacts {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.block_content_contacts_l,
	.block_content_contacts_r {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 80%;
		padding: 10% 0%;
	}

	.block_content_contacts_l input {
		font-size: 18px;
		line-height: 24px;
	}

	.block_content_contacts_l input::placeholder {
		font-size: 18px;
		line-height: 24px;
		/* identical to box height */

		color: #cccccc;
	}

	.block_content_contacts_r a {
		font-size: 20px;
		line-height: 20px;
	}

	.block_content_contacts_l button {
		font-size: 18px;
		color: #2a3587;
		padding: 0 20px;
		height: 40px;
		width: 50%;
		margin: 0 auto;
	}
}

@media (max-width: 479px) {
	.main_contacts {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background-image: none;
		background-repeat: no-repeat;
		background-position: unset;
		background-size: 0;
		padding: 0 5%;
	}

	.content_contacts {
		width: 100%;
		margin-bottom: 100px;
	}

	.content_contacts h1 {
		font-size: 28px;
		line-height: 28px;
	}

	.content_contacts h3 {
		font-size: 14px;
		line-height: 16px;
		width: 80%;
	}

	.block_content_contacts {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.block_content_contacts_l,
	.block_content_contacts_r {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		padding: 10% 0%;
	}

	.block_content_contacts_l input {
		font-size: 14px;
		line-height: 24px;
	}

	.block_content_contacts_l input::placeholder {
		font-size: 14px;
		line-height: 24px;
		/* identical to box height */

		color: #cccccc;
	}

	.block_content_contacts_r a {
		font-size: 18px;
		line-height: 18px;
	}

	.block_content_contacts_l button {
		font-size: 16px;
		color: #2a3587;
		padding: 0 10px;
		height: 34px;
		width: 40%;
		margin: 0 auto;
	}
}
</style>
