// axios
import axios from "axios";

// const apiUrl = "http://127.0.0.1:8000/api/";
const apiUrl = "https://exmoney.exchange/api/";


// const apiUrl = process.env.VUE_APP_API_ENDPOINT

export default axios.create({
	baseURL: apiUrl,
});
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";


axios.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem("access_token");

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	});