import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
// синтаксис в компоненте - {{ $t("logIn") }}
const messages = {
	en: {
		// Header
		logIn: "Log in",
		logOut: "Log out",
		Profile: "Profile",
		Close: "Close",
		Menu: "Menu",
		Main: "Main",
		Terms: "Terms",
		News: "News",
		Reviews: "Reviews",
		Contacts: "Contacts",
		// Форма обмена
		form_header_Exchange:"Exchange",
		form_header_to:"to",
		form_header_DateInput:"Data input",
		form_header_Payment:"Payment",
		form_header_Completion:"Completion",
		form_header_Date:"Date",
		form_header_Copy:"Copy",
		form_header_RemainingTime:"Remaining time",
		form_body_STATUS:"STATUS",
		form_body_STEP:"STEP",
		form_body_MESSAGE:"MESSAGE",
		form_footer_updated:"The page is updated automatically every second.",
		form_rule_1_title:"Attention!",
		form_rule_1_body_1:"This address only accepts ",
		form_rule_1_body_2:". Please do not send any other currency to this address. They will not be credited.",
		form_rule_2_title:"A form for exchanging cryptocurrencies",
		form_rule_2_body:"In the cryptocurrency exchange form, you can select the desired cryptocurrency pair and provide the necessary details of the transaction. Simply enter the amount of cryptocurrency you want to exchange and receive the corresponding amount of another currency in return.",
		form_rule_3_title:"Current exchange rates.",
		form_rule_3_body:"Here you will also find current exchange rates for various cryptocurrency pairs. Our rates are updated in real time so that you can always make the best possible deal.",
		form_rule_4_title:"Instructions on the exchange procedure.",
		form_rule_4_body:"To help you complete a successful exchange, we provide detailed instructions on the exchange procedure. You will be able to familiarize yourself with all the steps of the operation step by step and confidently complete your exchange without any problems.",
		form_rule_5_body:'Exchange your cryptocurrency assets now and take advantage of our advantages to get the best conditions on the cryptocurrency exchange market!',
		// Этапы обмена
		txt_stage_0:"Creating requisites",
		txt_stage_1:"Deposit pending",
		txt_stage_2:"Payment processing",
		txt_stage_3:"Exchange initiated",
		txt_stage_4:"Exchange completed",
		txt_stage_5:"Payment formation",
		txt_stage_6:"Payout",
		// kyc_dashboard
		kyc_dashboard_title_h3:"KYC Verification",
		kyc_dashboard_status_title:"KYC verification status:",
		kyc_dashboard_status_1:"On inspection!",
		kyc_dashboard_status_2:"Confirmed!",
		kyc_dashboard_status_3:"Refused!",
		kyc_dashboard_status_1_explain1:"Wait for confirmation. The process takes from 30 minutes to 4 hours.",
		kyc_dashboard_status_2_explain2:"Your data has been checked and confirmed, now you can use the exchange for fiat funds!",
		kyc_dashboard_status_3_explain3:"Unfortunately, you have been refused! Please contact us at ",
		kyc_dashboard_rule_h3:"Mandatory for exchange  with fiat currencies!",
		kyc_dashboard_rule_h5:"You need to provide:",
		kyc_dashboard_rule_li1_1:"Photo of one of the identity documents:",
		kyc_dashboard_rule_li1_2:"- Passport (first page)",
		kyc_dashboard_rule_li1_3:"- ID card (front side)",
		kyc_dashboard_rule_li1_4:"- Identity document (front side)",
		kyc_dashboard_rule_li2_1:"A photo (selfie) of a person with an identity document.",
		kyc_dashboard_upload_1:"Upload a photo of one of the identity documents",
		kyc_dashboard_upload_2:"Upload a photo (selfie) with an identity document",
		kyc_dashboard_selected_file:"Select file",
		kyc_dashboard_required:"Required!",
		kyc_dashboard_agree_1:"I agree to the processing",
		kyc_dashboard_agree_2:"my personal data.",
		kyc_dashboard_btn_submit:"Submit",
		// CARD_dashboard
		card_dashboard_title_h3:"Card Verification",
		card_dashboard_title_h3_2:"Mandatory for exchange with fiat currencies!",
		card_dashboard_title_rules:"You need to provide:",
		card_dashboard_li_1_1:"To verify a physical card, you need:",
		card_dashboard_li_1_2:"- Enter the card number in the field",
		card_dashboard_li_1_3:"- Take a picture of the card (front side), the first and last 4 numbers should be visible on the card (see example)",
		card_dashboard_li_2_1:"To verify the virtual card, you need:",
		card_dashboard_li_2_2:"- Enter the card number in the field",
		card_dashboard_li_2_3:"- Screenshot of online banking, where the card is visible (See example)",
		card_dashboard_li_2_4:"- Photo with full name, date, signature and open online banking where the card is visible (See example)",
		card_dashboard_physic_number:"Card number",
		card_dashboard_physic_err_1_1:"Wallet address is required!",
		card_dashboard_physic_err_1_2:"Invalid wallet address format!",
		card_dashboard_physic_title_1:"Upload a photo of physical card",
		card_dashboard_selected_file:"Select file",
		card_dashboard_required:"Required!",
		card_dashboard_agree_1:"I agree to the processing",
		card_dashboard_agree_2:"my personal data.",
		card_dashboard_btn_submit:"Submit",
		card_dashboard_virtual_title_1:"Upload a screanshot of your bank account",
		card_dashboard_virtual_title_2:"Upload a photo with full name, date, signature and open online banking where the card is visible",
		card_dashboard_list_titles_1:"Number Card",
		card_dashboard_list_titles_2:"Type",
		card_dashboard_list_titles_3:"Status",
		card_dashboard_list_titles_1_fake:"Number Card",
		card_dashboard_list_titles_2_fake:"Status",
		card_dashboard_list_status_1_fake:"Not added Card",
		card_dashboard_list_status_2_fake:"None",
		card_dashboard_list_type_1:"Physical",
		card_dashboard_list_type_2:"Virtual",
		card_dashboard_list_status_1:"On inspection!",
		card_dashboard_list_status_2:"Confirmed!",
		card_dashboard_list_status_3:"Refused!",
		// card_dashboard:"",
		// card_dashboard:"",
		// card_dashboard:"",
		// card_dashboard:"",
		// card_dashboard:"",
		// Portfolio

		// Services

		// About studio

		// Contacts

		// Footer
	},
	ua: {
		// Header
		logIn: "Вхід",
		logOut: "Вихід",
		Profile: "Профіль",
		Close: "Закрити",
		Menu: "Меню",
		Main: "Головна",
		Terms: "Умови",
		News: "Новини",
		Reviews: "Відгуки",
		Contacts: "Контакти",
		// Форма обмена
		form_header_Exchange:"Обмін",
		form_header_to:"на",
		form_header_DateInput:"Ввід даних",
		form_header_Payment:"Оплата",
		form_header_Completion:"Завершення",
		form_header_Date:"Дата",
		form_header_Copy:"Копіювати",
		form_header_RemainingTime:"Залишилось часу",
		form_body_STATUS:"СТАТУС",
		form_body_STEP:"КРОК",
		form_body_MESSAGE:"Повідомлення",
		form_footer_updated:"Сторінка оновлюється автоматично щосекунди.",
		form_rule_1_title:"Увага!",
		form_rule_1_body_1:"Ця адреса приймає лише ",
		form_rule_1_body_2:". Будь ласка, не надсилайте іншу валюту на цю адресу. Вони не будуть зараховані.",
		form_rule_2_title:"Форма для обміну криптовалют",
		form_rule_2_body:"У формі для обміну криптовалют ви можете вибрати потрібну криптовалютну пару та вказати необхідні деталі операції. Просто введіть суму криптовалюти, яку ви хочете обміняти, та отримайте відповідний обсяг іншої валюти натомість.",
		form_rule_3_title:"Поточні курси обміну",
		form_rule_3_body:"Тут ви також знайдете поточні курси обміну для різних криптовалютних пар. Наші курси оновлюються в реальному часі, щоб ви завжди могли укласти найвигіднішу для себе угоду.",
		form_rule_4_title:"Інструкції щодо процедури обміну",
		form_rule_4_body:"Щоб допомогти вам здійснити успішний обмін, ми надаємо докладні інструкції щодо процедури обміну. Ви зможете крок за кроком ознайомитися з усіма етапами операції та впевнено завершити свій обмін без проблем.",
		form_rule_5_body:"Обмінюйте свої криптовалютні активи зараз та скористайтеся нашими перевагами для отримання найкращих умов на ринку обміну криптовалют!",
		// Этапы обмена
		txt_stage_0:"Створення реквізитів",
		txt_stage_1:"Очікування депозиту",
		txt_stage_2:"Обробка платежу",
		txt_stage_3:"Розпочато обмін",
		txt_stage_4:"Завершено обмін",
		txt_stage_5:"Формування виплати",
		txt_stage_6:"Виплата",
		// kyc_dashboard
		kyc_dashboard_title_h3:"Перевірка KYC",
		kyc_dashboard_status_title:"Статус перевірки KYC:",
		kyc_dashboard_status_1:"Перевіряється!",
		kyc_dashboard_status_2:"Підтверджено!",
		kyc_dashboard_status_3:"Відмовлено!",
		kyc_dashboard_status_1_explain1:"Очікуйте підтвердження. Процес займає від 30 хв до 4 годин.",
		kyc_dashboard_status_2_explain2:"Ваші дані перевірено та підтверджено,тепер Ви можете користуватись обміном для фіатних коштів!",
		kyc_dashboard_status_3_explain3:"Нажаль, Вам відмовлено! Для деталей зв'яжіться з нами ",
		kyc_dashboard_rule_h3:"Обов'язковий для обміну на фіатні валюти!",
		kyc_dashboard_rule_h5:"Вам необхідно надати:",
		kyc_dashboard_rule_li1_1:"Фото одного з документів, що посвідчують особу:",
		kyc_dashboard_rule_li1_2:"- Паспорт (перша сторінка)",
		kyc_dashboard_rule_li1_3:"- ID-картка (передній бік)",
		kyc_dashboard_rule_li1_4:"- документ, що засвідчує особу (передня сторона)",
		kyc_dashboard_rule_li2_1:"Фото (селфі) особи з документом, що посвідчує особу.",
		kyc_dashboard_upload_1:"Завантажте фотографію одного з документів, що посвідчують особу",
		kyc_dashboard_upload_2:"Завантажте фото (селфі) з документом, що посвідчує особу",
		kyc_dashboard_selected_file:"Обрати файл",
		kyc_dashboard_required:"Обов'язкове поле",
		kyc_dashboard_agree_1:"Я погоджуюся на обробку ",
		kyc_dashboard_agree_2:"моїх персональних даних.",
		kyc_dashboard_btn_submit:"Надіслати",
		// CARD_dashboard
		card_dashboard_title_h3:"Верифікація картки",
		card_dashboard_title_h3_2:"Обов'язковий для обміну на фіатні валюти!",
		card_dashboard_title_rules:"Вам необхідно надати:",
		card_dashboard_li_1_1:"Для верефикації фізичної карти потрібно:",
		card_dashboard_li_1_2:"- Ввести номер карти у поле",
		card_dashboard_li_1_3:"- Сфотографувати карту(передній бік), на карті повинно бути видно перші та останні 4 цифри(Див.приклад)",
		card_dashboard_li_2_1:"Для верефикації віртуальної карти потрібно:",
		card_dashboard_li_2_2:"- Ввести номер карти у поле",
		card_dashboard_li_2_3:"- Скріншот онлайн-банкінгу, де видно карту (Див.приклад)",
		card_dashboard_li_2_4:"- Фото з ПІБ, Датою, підписом та відкритим онлайн-банкінгом, де видно карту (Див.приклад)",
		card_dashboard_physic_number:"Номер карти",
		card_dashboard_physic_err_1_1:"Необхідна адреса гаманця!",
		card_dashboard_physic_err_1_2:"Недійсний формат адреси гаманця!",
		card_dashboard_physic_title_1:"Завантажте фотографію фізичної картки",
		card_dashboard_selected_file:"Обрати файл",
		card_dashboard_required:"Обов'язкове поле",
		card_dashboard_agree_1:"Я погоджуюся на обробку ",
		card_dashboard_agree_2:"моїх персональних даних.",
		card_dashboard_btn_submit:"Надіслати",
		card_dashboard_virtual_title_1:"Завантажте скріншот з банківського рахунку",
		card_dashboard_virtual_title_2:"Завантажте фото з ПІБ, датою, підписом та власним онлайн-банкінгом, де видно картку",
		card_dashboard_list_titles_1:"Номер карти",
		card_dashboard_list_titles_2:"Тип",
		card_dashboard_list_titles_3:"Статус",
		card_dashboard_list_titles_1_fake:"Номер карти",
		card_dashboard_list_titles_2_fake:"Статус",
		card_dashboard_list_status_1_fake:"Карта не додана",
		card_dashboard_list_status_2_fake:"Немає",
		card_dashboard_list_type_1:"Фізична",
		card_dashboard_list_type_2:"Віртуальна",
		card_dashboard_list_status_1:"Перевіряється!",
		card_dashboard_list_status_2:"Підтверджено!",
		card_dashboard_list_status_3:"Відмовлено!",
		// Portfolio

		// Services

		// About studio

		// Contacts

		// Footer
	},
};

const i18n = new VueI18n({
	locale: "en", // set locale
	fallbackLocale: "en", // set fallback locale
	messages, // set locale messages
});

export default i18n;
